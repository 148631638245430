<div class="bbody">
  <div class="temp-wrapper">
    <div class="px">
      <div class="px__body">
        <div class="px__body__cut"></div>
        <div class="px__body__speaker"></div>
        <div class="px__body__sensor"></div>

        <div class="px__body__mute"></div>
        <div class="px__body__up"></div>
        <div class="px__body__down"></div>
        <div class="px__body__right"></div>
      </div>

      <div class="px__screen">
        <div class="px__screen__">
          <div *ngIf="loading" class="loading-spinner">
            <!-- Add your loading spinner component or HTML/CSS here -->
          </div>
          <div
            *ngIf="!loading"
            class="px__screen__frame"
            style="overflow-y: scroll"
          >
            <app-template01
              *ngIf="templateId == 1"
              [buttonColor]="selectedColor"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [firstName]="FirstName"
              [lastName]="LastName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
              [youtubeArray]="YoutubeArray"
              [buttons]="buttons"
            ></app-template01>
            <app-template02
              *ngIf="templateId == 2"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [youtubeArray]="YoutubeArray"
              [buttons]="buttons"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template02>
            <app-template03
              *ngIf="templateId == 3"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [jobTitle]="JobTitle"
              [buttons]="buttons"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template03>
            <app-template04
              *ngIf="templateId == 4"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [firstName]="FirstName"
              [buttons]="buttons"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template04>
            <app-template05
              *ngIf="templateId == 5"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [lastName]="LastName"
              [buttons]="buttons"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template05>
            <app-template06
              *ngIf="templateId == 6"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [buttons]="buttons"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template06>
            <app-template07
              *ngIf="templateId == 7"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [buttons]="buttons"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template07>
            <app-template08
              *ngIf="templateId == 8"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [buttons]="buttons"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template08>

            <app-template09
              *ngIf="templateId == 9"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [youtubeArray]="YoutubeArray"
              [buttons]="buttons"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            ></app-template09>

            <app-template10
              *ngIf="templateId == 10"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [email]="Email"
              [buttons]="buttons"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            >
            </app-template10>
            <app-template11
              *ngIf="templateId == 11"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [buttons]="buttons"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            >
            </app-template11>
            <app-template12
              *ngIf="templateId == 12"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [buttons]="buttons"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            >
            </app-template12>
            <app-template13
              *ngIf="templateId == 13"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [buttons]="buttons"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            >
            </app-template13>
            <app-template14
              *ngIf="templateId == 14"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [phoneAllow]="PhoneAllow"
              [buttons]="buttons"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            >
            </app-template14>
            <app-template15
              *ngIf="templateId == 15"
              [linkedin]="Linkedin"
              [twitter]="Twitter"
              [facebook]="Facebook"
              [youtube]="Youtube"
              [instagram]="Instagram"
              [gpa]="gpa"
              [age]="age"
              [weight]="weight"
              [height]="height"
              [grade]="grade"
              [school]="school"
              [buttonColor]="selectedColor"
              [firstName]="FirstName"
              [youtubeArray]="YoutubeArray"
              [umyotubeArray]="UmyotubeArray"
              [vimeoArray]="VimeoVideoArray"
              [lastName]="LastName"
              [companyName]="CompanyName"
              [jobTitle]="JobTitle"
              [content]="ccontent"
              [email]="Email"
              [address]="Address"
              [phone]="PhoneNumber"
              [buttons]="buttons"
              [phoneAllow]="PhoneAllow"
              [saveCard]="SaveCard"
              [inviteCode]="InviteCode"
              [forwardCard]="ForwardCard"
              [referal]="referalCode"
              [photo]="Photo"
              [logo]="Logo"
              [productImages]="ProductImages"
            >
            </app-template15>
            <!-- <i class="fa fa-apple"></i> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="temp-wrapper temp-wrapper--wide">
    <div class="px px--ls">
      <div class="px__body">
        <div class="px__body__cut"></div>
        <div class="px__body__speaker"></div>
        <div class="px__body__sensor"></div>

        <div class="px__body__mute"></div>
        <div class="px__body__up"></div>
        <div class="px__body__down"></div>
        <div class="px__body__right"></div>
      </div>

      <div class="px__screen">
        <div class="px__screen__">
          <div class="px__screen__frame">
            <i class="fa fa-apple"></i>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</div>

<div
  class="modal fade"
  id="shareCard"
  tabindex="-1"
  aria-labelledby="shareCardLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="shareCardLabel">Forward Card</h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div
          class="row"
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
          "
        >
          <h5 style="width: 100%; text-align: center; padding-bottom: 25px">
            https://umyogreeks.site/cards/share-card/{{ shareCardId }}
          </h5>

          <h3
            style="
              width: 100%;
              text-align: center;
              padding-bottom: 25px;
              padding-top: 40px;
              font-size: medium;
            "
          >
            Copy link to share your card.
          </h3>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
